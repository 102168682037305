// Global
import classNames from 'classnames';

// Lib
import { getThemeClasses, Theme } from 'lib/get-theme';

// Local
import Container from 'components/helpers/Container/Container';
import PlaceholderWrapper from 'components/helpers/PlaceholderWrapper/PlaceholderWrapper';
import { getEnum } from 'lib/get-enum';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { Feature } from '.generated/templates/Feature.Items.model';
import { MissingDataSource } from 'components/helpers/EditingHelpText';

import React, { LegacyRef, useEffect, useRef, useState } from 'react';
import HeadingWrapper, {
  HeadingWrapperProps,
} from 'components/helpers/HeadingWrapper/HeadingWrapper';

export type LayoutPageSectionProps = Sitecore.Override.ComponentBase &
  Feature.Components.PageLayouts.Fields.PageSection;

export type LayoutPageSectionShellProps = HeadingWrapperProps & {
  theme?: Theme;
  children: React.ReactNode;
  additionalClassNames?: string;
  innerClassNames?: string;
  dataComponent?: string;
  innerRef?: LegacyRef<HTMLDivElement>;
};

export const LayoutPageSectionShell = ({
  children,
  additionalClassNames,
  innerClassNames,
  dataComponent,
  theme,
  innerRef,
  ...fields
}: LayoutPageSectionShellProps): JSX.Element => {
  const overlapClassNames = innerClassNames ? innerClassNames : '';

  return (
    <Container
      innerRef={innerRef}
      className={classNames('block', 'relative', additionalClassNames, getThemeClasses(theme))}
      dataComponent={dataComponent}
      theme={theme}
      dataOverlapClass={overlapClassNames}
    >
      <div className={classNames('container-content-padding-x', 'md:space-y-12', 'space-y-8')}>
        <HeadingWrapper {...fields} />
        {children}
      </div>
    </Container>
  );
};

const LayoutPageSection = ({ fields, rendering }: LayoutPageSectionProps): JSX.Element => {
  const [isSubnav, setIsSubNav] = useState(false);
  const [nextSectionClass, setNextSectionClass] = useState('');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const main = ref.current;

    const prev = main?.previousElementSibling;

    setNextSectionClass(
      prev?.getAttribute('data-next-spacing') ||
        prev?.querySelector('[data-next-spacing]')?.getAttribute('data-next-spacing') ||
        ''
    );
    setIsSubNav(
      main?.previousSibling?.contains(
        document.querySelector('[data-component="authorable/navigation/subnavigation"]')
      ) || false
    );
  }, []);

  const additionalClasses = isSubnav ? classNames('py-12') : classNames('py-8 md:py-16 lg:py-30');

  const themeValue = getEnum<Theme>(fields?.backgroundStyle);

  if (!fields) {
    return <MissingDataSource />;
  }

  return (
    <LayoutPageSectionShell
      innerRef={ref}
      theme={themeValue}
      additionalClassNames={additionalClasses}
      innerClassNames={nextSectionClass}
      dataComponent="authorable/layout/layout-page-section"
      {...fields}
    >
      <PlaceholderWrapper wrapperSize="normal" name="BCBSAZ-Page-Section" rendering={rendering} />
    </LayoutPageSectionShell>
  );
};

export default LayoutPageSection;
