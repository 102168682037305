import { Foundation } from '.generated/templates/Foundation.Items.model';
import { Feature } from '.generated/templates/Feature.Items.model';
import { Project } from '.generated/templates/Project.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { Field, RouteData, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { HealthLibraryStory } from 'lib/server-utils/health-library';
import { TemplatesIds } from 'lib/sitecore/template-ids';
import { guidIsOneOf } from 'lib/string-utils';
import { getArticleMetaData, getStoryImageUrls } from 'lib/utils/article-utils';
import { routeHasComponent } from 'lib/utils/component-util';
import Head from 'next/head';

type PageRoute = RouteData & Project.Pages.Fields.Page;

export type PageMetaDataProps = {
  route?: RouteData | null;
  language?: string;
  newsFeed?: string;
  healthLibraryArticle?: HealthLibraryStory;
};

const PageMetaData = ({ route, language, healthLibraryArticle, newsFeed }: PageMetaDataProps) => {
  const context = useSitecoreContext();

  const displayLanguage = language === 'es' ? 'Spanish' : 'English';

  const page = route as PageRoute;

  const isMediaArticle = route?.templateId === TemplatesIds.MediaArticle;
  const article = route as Project.Pages.Fields.NewsroomArticle;

  const articleHealthLibraryTopic = article.fields
    ?.healthLibraryTopic as unknown as Feature.Data.HealthLibrary.Fields.HealthLibraryTopic;

  const mediaArticle = route as Project.Pages.Fields.NewsroomMediaArticle;

  const fields = page.fields;

  const robots = [
    ...(isMediaArticle || fields?.doNotIndex?.value ? ['noindex'] : []),
    ...(isMediaArticle || fields?.noFollow?.value ? ['nofollow'] : []),
    ...(isMediaArticle || fields?.excludeFromSitemap?.value ? ['noarchive'] : []),
  ];

  const title =
    healthLibraryArticle?.Headline ||
    article?.fields?.articleTitle?.value ||
    fields?.browserTitle?.value ||
    `${route?.displayName} | AZBlue`;

  // Used for Mobile Devices when saving bookmark to home screen
  const applicationName = context.sitecoreContext?.applicationName;

  const ogImgUrl = healthLibraryArticle ? getStoryImageUrls(healthLibraryArticle).wideImgUrl : null;
  const { category, publishedDate, updatedDate } = getArticleMetaData(
    healthLibraryArticle || article
  );

  const taxonomyAudience =
    article.fields?.taxonomyAudience?.map((x) => (x.fields.title as Field<string>)?.value) ?? [];
  const taxonomySegment =
    article.fields?.taxonomySegment?.map((x) => (x.fields.title as Field<string>)?.value) ?? [];

  const realLastUpdatedDate = !updatedDate || updatedDate.year < 1000 ? publishedDate : updatedDate;

  const sourceOutlet = mediaArticle?.fields?.sourceOutlet as Sitecore.Override.ItemEx &
    Foundation.Data.Values.Fields.MediaOutlet;

  // Note, these tags need to be direct children of <Head> per NextJS docs.
  return (
    <>
      <Head>
        <title>{title}</title>

        {applicationName && (
          <>
            <meta name="application-name" content={applicationName} />
            <meta name="apple-mobile-web-app-title" content={applicationName} />
          </>
        )}
        {
          // Only render if specified
          fields?.canonicalUrl?.value?.href ? (
            <link rel="canonical" href={fields?.canonicalUrl?.value?.href} />
          ) : (
            <></>
          )
        }

        <meta name="description" content={fields?.metaDescription?.value} />
        <meta name="keywords" content={fields?.metaKeywords?.value} />

        <meta name="robots" content={robots.join(', ')} />
        <meta name="language" content={displayLanguage} />
        {
          // Robots meta for coveo specifically
          isMediaArticle || fields?.showInSearchResults?.value ? (
            <meta name="coveobot" content="all" />
          ) : (
            <meta name="coveobot" content="noindex nofollow" />
          )
        }

        <meta name="searchtype" content={getType(page)} />
        <meta name="hasForm" content={routeHasComponent(route, 'Formstack').toString()} />

        <meta name="category" content={category || ''} />
        <meta name="author" content={article.fields?.articleAuthor?.value} />
        <meta name="newsFeed" content={newsFeed} />
        <meta name="publishedDate" content={publishedDate?.toRFC2822()} />
        <meta name="updatedDate" content={realLastUpdatedDate?.toRFC2822()} />
        <meta name="year" content={realLastUpdatedDate?.year.toString()} />
        <meta name="sourceOutlet" content={sourceOutlet?.fields.mediaOutletName?.value} />

        {healthLibraryArticle ? <meta name="healthType" content={category || ''} /> : <></>}

        {article ? (
          <meta
            name="health_topic"
            content={articleHealthLibraryTopic?.fields?.topicName?.value || ''}
          />
        ) : (
          <></>
        )}
        <meta name="taxonomyAudience" content={taxonomyAudience.join(';')} />
        <meta name="taxonomySegment" content={taxonomySegment.join(';')} />
        <meta
          name="featuredImage"
          content={
            ogImgUrl ||
            article.fields?.imageFeatured?.value?.src ||
            article.fields?.imageFallback?.value?.src
          }
        />
      </Head>

      {isMediaArticle ? (
        // No social tags for media articles
        <></>
      ) : (
        <Head>
          {/* OG tags are the only common meta tags that use "property" instead of "name" */}
          <meta property="og:title" content={`${fields?.openGraphTitle?.value || title}`} />
          <meta property="og:description" content={fields?.openGraphDescription?.value} />
          <meta property="og:image" content={ogImgUrl || fields?.openGraphImage?.value?.src} />
          <meta property="og:url" content={fields?.openGraphUrl?.value.href} />

          <meta name="twitter:title" content={fields?.twitterTitle?.value} />
          <meta name="twitter:description" content={fields?.twitterDescription?.value} />
          <meta name="twitter:image" content={ogImgUrl || fields?.twitterImage?.value?.src} />
          <meta name="twitter:site" content={fields?.twitterSite?.value.href} />
        </Head>
      )}
    </>
  );
};
const getType = (page: PageRoute) => {
  const isArticle = guidIsOneOf(page?.templateId, [
    TemplatesIds.NewsArticle,
    TemplatesIds.MediaArticle,
    TemplatesIds.HealthLibraryWildcard,
  ]);
  if (isArticle) {
    return 'article';
  }
  return 'page';
};

export default PageMetaData;
